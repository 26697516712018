import { Divider, Typography } from "@mui/material";
import { Image } from "components/shared";
import { selectSharedLayoutContent } from "components/shared/layout/store/layoutContentSlice";
import { animate, m, useInView, useMotionValue } from "framer-motion";
import useFlubber from "lib/flubber/useFlubber";
import { useAppSelector } from "lib/redux";
import Link from "next/link";
import { useEffect, useMemo, useRef } from "react";
import { FooterLink } from ".";
import { selectMainLayoutContent } from "../store/layoutContentSlice";

const paths = [
  "M1006.5 92.4c184.8 6.3 366-36.7 433.5-59V0H0v92.4C63 74.2 183.6 44 347.8 40s427.7 44.4 658.7 52.4",
  "M1015.5 91c190 9.6 376.1-55.3 445.5-89v-20H-19V91a996 996 0 0 1 357.4-79c168.8-6 439.6 67 677 79Z",
];

const decoratorPath =
  "M333.8 63.4C130 69.7-69.6 26.6-144 4.3V-9h1587v72.4a1635 1635 0 0 0-383.3-52.5c-181-4-471.3 44.5-726 52.5Z";

const Footer = () => {
  const { logos } = useAppSelector(selectSharedLayoutContent);
  const {
    socials,
    footer: { content, nav, disclaimer },
  } = useAppSelector(selectMainLayoutContent);

  const progressRange: [number, number] = useMemo(() => [0, 1], []);
  const waveAnimate = useMotionValue(0);
  const path = useFlubber(waveAnimate, progressRange, paths, 11);

  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (!isInView) {
      waveAnimate.set(0);
      return;
    }

    animate(...progressRange, {
      stiffness: 100,
      damping: 10,
      duration: 1,
      onUpdate: waveAnimate.updateAndNotify,
    });
  }, [isInView, waveAnimate, progressRange]);

  return (
    <footer className="relative">
      <svg
        className="absolute inset-0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 67"
        fill="none"
      >
        <m.path d={decoratorPath} stroke="var(--palette-secondary-main)" strokeWidth={5} />
      </svg>
      <div ref={ref} className="bg-primary-dark">
        <svg className="-m-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 93">
          <m.path d={path} fill="var(--palette-background-default)" />
        </svg>
        <div className="container mt-12 sm:mt-20">
          <div className="flex flex-col sm:flex-row sm:gap-x-10">
            <div className="w-full max-w-[350px]">
              <Link href="/">
                <Image {...logos.dark} placeholder="empty" />
              </Link>
              <Typography className="my-6 text-balance text-dark" component="h3" variant="h5">
                {content}
              </Typography>
            </div>
            <div className="mt-6 flex max-w-max justify-between gap-x-10 xs:gap-x-16 sm:ml-auto sm:justify-normal">
              {nav.items
                .filter((item) => item.title !== "Policy")
                .map(({ title, children }) => (
                  <div key={title} className="w-[19.2rem]">
                    <Typography className="mb-8 text-dark" component="h4" variant="h5">
                      {title}
                    </Typography>
                    <ul className="flex flex-col flex-wrap gap-5">
                      {children?.map((link) => <FooterLink key={link.title} {...link} />)}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          <ul className="mt-16 flex gap-5 sm:mb-16 sm:mt-0">
            {socials.map(({ title, icon, url }, i) => (
              <li key={i}>
                <Link href={url} aria-label={title} target="_blank" rel="noreferrer">
                  <Image {...icon} />
                </Link>
              </li>
            ))}
          </ul>
          <Divider className="my-8 w-full border-primary-main" />
          <div className="flex flex-col gap-y-8 sm:flex-row sm:items-center sm:gap-x-10">
            <Typography className="w-full max-w-[360px] text-dark" variant="p3">
              ©{new Date().getFullYear()} <Link href="/">Briick.</Link> All rights
              reserved
            </Typography>
            <ul className="flex flex-col gap-y-6 sm:ml-auto sm:flex-row sm:gap-x-16">
              {nav.items
                .find((item) => item.title === "Policy")
                ?.children?.map((link) => (
                  <FooterLink key={link.title} className="w-[19.2rem]" {...link} />
                ))}
            </ul>
          </div>
          <Divider className="my-8 w-full border-primary-main" />
          <Typography className="max-w-[75.9rem] pb-8 text-dark" variant="p3">
            {disclaimer}
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
